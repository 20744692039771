<template>
    <div>
        <div class="card_mine">
            <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/card_back.png" class="home_swipe_back" alt="">
            <div style="padding-top: 50px;">
                <div style="width: 100%;">
                    <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/cardn.png" class="swipe_back swipe_back_mine" alt="">
                </div>
                <div class="card_mine_detail">
                    <div style="height: 100%;display: flex;flex-direction: column;">
                        <div class="card_detail_top">
                            <span>黑卡会员</span>
                        </div>
                        <div style="display:flex;width:100%;flex-grow: 1;">
                            <div style="font-size: 12px;flex: 1 0 auto;padding:15px 0 0 10px;">
                                <p>尊享专属特权，包月服务更优惠</p>
                            </div>
                            <div style="flex: 0 0 auto;">
                                <img src="../../assets/img/king.png" style="display: block;width: 60px;float:right;margin:5px 20px 0 0;" alt="">
                            </div>
                        </div>
                        <div class="card_detail_bottom" style="margin-bottom: 5px;">
                            <div>您的黑卡会员有效期至{{vip.end_date}}</div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div style="padding-top: 50px;">-->
<!--                <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/cardn.png" class="swipe_back swipe_back_mine" alt="">-->
<!--                <div class="card_mine_detail">-->
<!--                    <div>-->
<!--                        <div class="card_detail_top">-->
<!--                            <span>黑卡会员</span>-->
<!--                            <span @click="recharge">立即续费></span>-->
<!--                        </div>-->
<!--                        <div class="card_detail_middle">-->
<!--                            <div>-->
<!--                                <p><span>85</span>折</p>-->
<!--                                <p>购课优惠</p>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <p><span>30</span>次/月</p>-->
<!--                                <p>点评补发</p>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <p><span>1</span>次/月</p>-->
<!--                                <p>店铺装修</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="card_detail_bottom">-->
<!--                            <div>您的黑卡会员有效期至{{vip.end_date}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div>
            <van-divider style="border:none;color: #000000;font-size: 16px;margin-bottom: 0;">
                <img src="../../assets/img/clip.png" style="height: 14px;margin-right: 10px;" alt="">
                黑卡会员尊享4项权益
                <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 10px;" alt="">
            </van-divider>
            <van-grid :column-num="2" :border="false" class="vip_rights">
                <van-grid-item v-for="(item,index) in rights" :key="index" class="rights" @click="apply(item)">
                    <van-image :src="item.icon" width="40"/>
                    <span :class="item.rights? 'card_span rights_button' :'card_span rights_button rights_button_false'">{{item.button}}</span>
                    <span>{{item.describe}}</span>
                </van-grid-item>
            </van-grid>
            <van-divider style="border:none;color: #000000;font-size: 16px;margin-bottom: 0;">
                <img src="../../assets/img/clip.png" style="height: 14px;margin-right: 10px;" alt="">
                你的1V1专属顾问
                <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 10px;" alt="">
            </van-divider>
            <div class="adviser" id="adviser">
                <div>
                    <div>
                        <div>
                            <van-image round width="66" height="66"
                                       :src="adviser.head_img"/>
                        </div>
                        <div>
                            <p><span>{{adviser.name}}</span><span>{{adviser.position}}</span></p>
                            <p>已服务{{adviser.service_count}}个商户</p>
                            <p>好评率{{adviser.praise_rate}}%</p>
                        </div>
                    </div>
                    <van-divider style="width: 260px;margin: 0 auto;"/>
                    <p>现在联系你的专属顾问</p>
                    <p>立享优先定制方案</p>
                    <div>
                        <van-image width="210"
                                   :src="adviser.qr_code"/>
                    </div>
                    <p>长按识别&nbsp;&nbsp;马上联系</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Toast, Dialog} from 'vant';

    export default {
        name: "VipCard",
        data() {
            return {
                rights: [
                    {icon: require('../../assets/img/rights_1_s.png'), rights:true, button: '立即咨询', describe: '专属顾问', click:'bottom',},
                    {icon: require('../../assets/img/rights_2_s.png'), rights:true, button: '增加访客收藏', describe: '搜索排名', message: '请联系咨询师或在线客服使用权益。'},
                    // {icon: require('../../assets/img/rights_3_s.png'), rights:true, button: '申请使用', describe: '辅助售后', message: '请联系咨询师或在线客服使用权益。'},
                    // {icon: require('../../assets/img/rights_4_s.png'), rights:true, button: '申请使用', describe: '平面设计', message: '请联系咨询师或在线客服使用权益。'},
                    {icon: require('../../assets/img/rights_5_s.png'), rights:true, button: '提升店铺星级', describe: '星级评分', message: '请联系咨询师或在线客服使用权益。'},
                    {icon: require('../../assets/img/rights_6_s.png'), rights:true, button: '专业设计师', describe: '店铺装修', message: '请联系咨询师或在线客服使用权益'},

                ],
                vip: {
                    vip_id: 0,
                    end_date:'0000年00月00日',
                },
                adviser: {},
            }
        },
        mounted() {
            this.loadData();
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        methods: {
            // 查询信息
            loadData() {
                let that = this;
                this.post('/api/client/course/vip', {}).then(result => {
                    if (result.code === 0) {
                        that.vip = result.data.vip;
                        that.adviser = result.data.adviser;
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            },
            recharge() {
                this.$router.push('/buyVip')
            },
            apply(item){
                if(item.message){
                    Dialog({
                        message: item.message,
                        closeOnClickOverlay: true,
                    });
                }
                if(item.click){
                     document.getElementById('adviser').scrollIntoView();
                }
            }
        }
    }
</script>

<style scoped>

</style>